/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:2c61a294-99b7-4e94-8477-36edca24116e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ACZNdnbii",
    "aws_user_pools_web_client_id": "6ttsi76sh10qbf40tmakqbo3ov",
    "oauth": {}
};


export default awsmobile;
