<template>
    <body>
    <nav>&nbsp;</nav>
    <nav>&nbsp;</nav>	
	<main>
        <div id="message"></div>
        <form >
            <div class="container-fluid noSort" ><!--v-if="lines.length > 0"-->
                <div id="sortableList" >
                    <div>
                        <div class="lineRow" v-if="linesAvailable">
                            <label class="col-sm-1 description align-middle bold font-weight-bold text-center">Position</label>
                            <label class="col-md-4 align-middle font-weight-bold text-center">Cue</label>
                            <label class="resizable col-md-4 col-md-offset-5 align-middle font-weight-bold text-center">Line</label>
                        </div>
                    </div>
                    <div v-for="line in lines" :key="line.position">
                        <div class="lineRow">
                        <label class="col-sm-1 description align-middle text-right font-weight-bold" v-bind:ref="'position_' + line.id">{{line.position}}</label>
                        <span><textarea class="resizable col-md-4  align-middle" v-model.lazy="line.cue" v-bind:ref="'cue_' + line.id"></textarea></span>
                        <span><textarea class="resizable col-md-4 col-md-offset-5 align-middle" v-model.lazy="line.line" v-bind:ref="'line_' + line.id"></textarea></span>
                        <span><button type="button" class="col-md-offset-9 btn btn-info align-middle" v-bind:id="'button_' + line.id" v-bind:ref="'button_' + line.id" v-on:click="callSaveLine">Save Line</button></span>
                        </div>
                    </div>
                </div>
            <hr class="noSort"/>
            <div class="noSort" v-if="linesAvailable">
                <label class="col-sm-1 description noSort align-middle text-right font-weight-bold">New:</label>
                <span><textarea class="resizable col-md-4  noSort align-middle" ref="newCue"></textarea></span>
                <span><textarea class="resizable col-md-4 col-md-offset-5 noSort align-middle" ref="newLine"></textarea></span>
                <span><button type="button" class="col-md-offset-9 noSort btn btn-info align-middle" v-on:click="callNewLine">Add Line</button></span>
            </div>
            </div>
            <div>
            <div class="description col-md-4 offset-4 bg-success" v-if="!linesAvailable">You can edit your scripts by selecting the script, act and scene from the upper-right.</div></div>
        </form>
    </main>
</body>
</template>

<script>
export default {
  name: 'scriptTable',
  props: {
    lines: Array
  },
  computed:{
      linesAvailable(){
          console.log(this.lines.length > 0);
          return this.lines.length > 0;
      }
  },
  methods:{
      callNewLine(){
        console.log("callNewLine");
        //script,act,scene,cue,line
        let newCue = this.$refs.newCue.value;
        let newLine = this.$refs.newLine.value;
        
        this.$emit('lineAdded',newCue,newLine);
        this.$refs.newCue.value="";
        this.$refs.newLine.value="";

      },
      callSaveLine($event){
        //index,cue,line,position
        console.log($event);
        let src = $event.srcElement.id;
        let idNum = src.replace("button_","");
        let newCue = this.$refs["cue_" + idNum][0].value;
        let newLine = this.$refs["line_" + idNum][0].value;
        let curPosition = this.$refs["position_" + idNum][0].innerText;
        this.$emit('lineChanged', idNum,newCue,newLine,curPosition);
      }
  }
}
</script>