<template>
  <header>
    <div class="navbar navbar-default">
      <div class="navbar-header">
        <img class="navbar-brand" alt="Vue logo" src="../assets/logo.png" width="50px">
        <h1 class="navbar-brand">My Drama Coach</h1>
      </div>
      <div>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <span><button type="button" class="btn btn-warning"  v-on:click="addScene">{{modalButtonText}}</button></span>
            <select class="breadcrumb-item" v-on:change="getActs" v-model="selector.script"><option></option><option class="text-capitalize" v-for="script in scripts" :key="script">{{script}}</option></select>
            <select class="breadcrumb-item" v-on:change="getScenes" v-model="selector.act"><option></option><option v-for="act in acts" :key="act">{{act}}</option></select>
            <select class="breadcrumb-item" v-on:change="getLines" v-model="selector.scene"><option></option><option v-for="scene in scenes" :key="scene">{{scene}}</option></select>
            
          </ol>
          <div v-show="showModal">
              Script: <input v-model="newScene.script"/>
              Act: <input size="3" type="number" v-model="newScene.act"/>
              Scene: <input size="3" type="number" v-model="newScene.scene"/>&nbsp;&nbsp;&nbsp;&nbsp;
              <button type="button" class="btn btn-warning" v-on:click="callAddScene">Add a New Scene</button>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'pageHeader',
  props: {
    scripts: Array,
    acts: Array,
    scenes: Array
  },
  computed:{
    modalVisible(){
      return this.showModelVisble;
    },
    showModal(){
      return this.showModelVisble;
    }
  },
  data(){
    return{
      selector:{
				script:"",
				act:"",
				scene:""
      },
      newScene:{
        script:"",
				act:"",
				scene:""
      },
      modalButtonText: "Add Scene",
      showModelVisble: false
    }
  },
  methods:{
    addScene(){
      if (this.showModelVisble){
        this.modalButtonText = "Add Scene";
        this.showModelVisble = false;
      }else{
        this.modalButtonText = "Cancel";
        this.showModelVisble = true;
      }
      this.newScene.script = "";
      this.newScene.act = "";
      this.newScene.scene = "";

    },
    getActs(){
      this.selector.act = null;
      this.selector.scene = null;
      let selectedScript = this.selector.script;
      this.$emit('scriptChanged',selectedScript);
    },
    getScenes(){
      this.selector.scene = null;
      let selectedScript = this.selector.script;
      let selectedAct = this.selector.act;
      this.$emit('actChanged',selectedScript,selectedAct);
    },
    getLines(){
      let selectedScript = this.selector.script;
      let selectedAct = this.selector.act;
      let selectedScene = this.selector.scene;
      this.$emit('sceneChanged',selectedScript,selectedAct,selectedScene);
    },
    async callAddScene(){
      let script = this.newScene.script;
      let act = this.newScene.act;
      let scene = this.newScene.scene;
      this.$emit('sceneAdded',script,act,scene);
      this.showModelVisble = false;
      this.modalButtonText = "Add Scene";
      this.newScene.script = "";
      this.newScene.act = ""; 
      this.newScene.scene = "";
      this.selector.script = script;
      this.selector.act = act;
      this.selector.scene = scene;
    }
  }

}
</script>
<style scoped>
  input[type=number]{
      width: 60px;
  }
</style>