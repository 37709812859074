import Vue from 'vue'
import App from './App.vue'
import '@aws-amplify/ui-vue';

//from https://www.youtube.com/watch?v=DFftniOvoLA
/*
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});
*/

//from aws documentation
/* 
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);
*/



Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')

