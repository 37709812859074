<template>
  <div>
    <div id="greeting" style="padding:100px 40% 0px 40%;width:100%;text-align:center">This visual editor pairs with the My Drama Coach Alexa Skill. You need an account set up on this site and then linked from your account in the Alexa App.</div>
    <amplify-authenticator>
      <div>
        <dramaCoach msg="Welcome to the Drama Coach Script Editor" ref="main"/>
        <div class="container">
          
          <div class="row justify-content-md-center">
            <amplify-sign-out class="btn btn-warning"></amplify-sign-out>
          </div>
        </div>
      </div>
    </amplify-authenticator>
    
  </div>
</template>

<script>
import dramaCoach from './components/dramaCoach.vue';
//from aws documentation
/* */
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);


export default {
  name: 'App',  
  components: {
    dramaCoach
    
  },
  props:{
    
  },
  
  data(){
    return{
      selector:{
          script:"",
          act:"",
          scene:""
        },
        test:"bob",
        scripts:[],
        acts:[],
        scenes:[],
        lines:[],
        newline:[],
        saveNeeded: true,
        userscripts:[]
    }
  },
  methods:{ 
    
  }
}

</script>