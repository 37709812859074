<template>
  <div>
    <pageHeader v-bind:scripts="scripts" v-bind:acts="acts" v-bind:scenes="scenes" v-bind:saveNeeded="saveNeeded" v-on:scriptChanged="updateActs" v-on:actChanged="updateScenes" v-on:sceneChanged="updateLines" v-on:sceneAdded="addNewScene"/>
    <span class="navbar-header">{{msg}}</span>
    <scriptTable v-bind:lines="lines" v-on:lineAdded="addLine" v-on:lineChanged="changeLine"/>

  </div>
</template>

<script>
import pageHeader from './pageHeader.vue';
import scriptTable from './scriptTable.vue';

//import axios for web service calling and Auth for checking creds
import axios from 'axios';
import {Auth} from 'aws-amplify';
import {Hub} from 'aws-amplify'; 


export default {
  name: 'dramaCoach',
  props: {
    msg: String
  },
  async mounted(){
    //console.log("mounted");
    //console.log(this.signedIn);
    await this.findUser();
    //console.log(this.signedIn);
    if (this.signedIn){
      
      this.getData(); 
      //console.log("mounted");
      
      
      
      
    }
  },
  updated(){
    this.getData();
    document.getElementById("greeting").innerText = "";
    document.getElementById("greeting").style = "";
  },
  created(){
    this.findUser();
    Hub.listen('auth', info => {
      //console.log(info);
      //console.log(info.payload.event);
      if(info.payload.event === "signIn"){
        //console.log("signing in...")
        this.findUser();
        this.getData();
      }else{
        this.clearData();
        this.signedIn = false;
      }
    })
  },
  components: {
    pageHeader,
    scriptTable
  },
  data(){
    return{
      info: '',
      user:'',
      selector:{
				script:"",
				act:"",
				scene:""
			},
			scripts:[],
			acts:[],
			scenes:[],
			lines:[],
			newline:[],
			saveNeeded: false,
			userscripts:[],
      signedIn:false
    }
  },
  methods:{
    async findUser(){
      try{
        const user = await Auth.currentAuthenticatedUser();
        console.log(user.signInUserSession.clockDrift);
        let curUser = await Auth.currentUserInfo();
        //console.log(JSON.stringify(curUser));
        this.user = curUser.attributes.sub;
        this.signedIn = true;
      }catch(err){
        console.log(err)
        this.signedIn = false;
      }
    },
    async getToken(){
      const session = await Auth.currentSession();
    
      const token = await session.idToken.jwtToken;
      //console.log(token);
      return await token;
    },
    async getData(){
      const jwt = await this.getToken()
      //console.log(jwt);

      const config = {
        headers: {
          Authorization: jwt,
          "Content-Type":"application/json"
        }
      }
      const payload = {
        uid: this.user,
        "test":"bob"
      }

      //console.log(config)
      const result = await axios.post("https://irufbatlqc.execute-api.us-east-1.amazonaws.com/v1/getlines",payload,config);
      this.userscripts = result.data;
      
      //populate scripts from the new data
      let record = 0;
      for(let x=0;x<this.userscripts.length;x++){
				record = this.userscripts[x];
				if (!this.scripts.includes(record['Script'])){ this.scripts.push(record['Script']);}
			}
			this.scripts.sort();
      
    },
    clearData(){
      //this.userscripts = [];
    },
    updateActs(script){
      //console.log("updating acts")
      this.acts = [];
      this.scenes = [];
      this.lines = [];
      this.selector.act = null;
      this.selector.scene = null;
      //let script = this.selector.script;
      let record = null;
      for(let x=0;x<this.userscripts.length;x++){
          record = this.userscripts[x];
          if(record['Script']==script){
              if (!this.acts.includes(record['Act'])){ this.acts.push(record['Act']);}
          }
      }
      this.acts.sort(function(a, b){return a - b});
    },
    updateScenes(script,act){
      this.scenes = [];
      this.lines = [];
      this.selector.scene = null;
      
      let record = null;
      for(let x=0;x<this.userscripts.length;x++){
          record = this.userscripts[x];
          if (record['Act'] == act && record['Script'] == script){
              if (!this.scenes.includes(record['Scene'])){ this.scenes.push(record['Scene']);}
          }
      }
      this.scenes.sort(function(a, b){return a - b});
    },
    updateLines(script,act,scene){
      //console.log('getLines');
      this.lines = [];
      
      //this will get all the lines for a script, act, and scene
      //todo: coming
      let record = null;
      let thisLine = null;
      for(let x=0;x<this.userscripts.length;x++){
        record = this.userscripts[x];
        if (record['Act'] == act && record['Script'] == script && record['Scene'] == scene){
          thisLine = {};
          thisLine.cue = record['Cue'];
          thisLine.line = record['Line'];
          thisLine.position = record['Position'];
          thisLine.id = record['id'];
          if (!this.lines.includes(thisLine)){ this.lines.push(thisLine);}
        }
      }
      
      this.lines.sort(function(a,b){
        return parseInt(a.position)-parseInt(b.position);
      });

      this.selector.script = script;
      this.selector.act = act;
      this.selector.scene = scene;
				//var sl = document.getElementById("sortableList");
				//var sortable = Sortable.create(sl,{filter:'.newLine'});
    },
    async addNewScene(script,act,scene){
      const jwt = await this.getToken()
      //console.log(jwt);

      const config = {
        headers: {
          Authorization: jwt,
          "Content-Type":"application/json"
        }
      }

      const payload = {
        "uid": this.user,
        "script":script,
        "act":act,
        "scene":scene
      }

      //console.log(payload)
      const result = await axios.post("https://irufbatlqc.execute-api.us-east-1.amazonaws.com/v1/addline",payload,config);
      //console.log("add scene results");
      console.log(result == 1);
      await this.getData();
      await this.updateActs(script);
      await this.updateScenes(script,act);
      await this.updateLines(script,act,scene);
    },
    async addLine(cue,line){
      //console.log("adding line");
      const jwt = await this.getToken()
      //console.log(jwt);

      const config = {
        headers: {
          Authorization: jwt,
          "Content-Type":"application/json"
        }
      }

      let script = this.selector.script;
      let act = this.selector.act;
      let scene = this.selector.scene;

      const payload = {
        "uid": this.user,
        "script":script,
        "act":act,
        "scene":scene,
        "cue":cue,
        "line":line
      }

      //console.log(payload)
      const result = await axios.post("https://irufbatlqc.execute-api.us-east-1.amazonaws.com/v1/addline",payload,config);
      //console.log("add scene results");
      console.log(result == 1);
      await this.getData();
      await this.updateLines(script,act,scene);

    },
    async changeLine(index,cue,line,position){
      const jwt = await this.getToken()
      //console.log(jwt);

      const config = {
        headers: {
          Authorization: jwt,
          "Content-Type":"application/json"
        }
      }

      const payload = {
        "uid": this.user,
        "index":index,
        "cue":cue,
        "line":line,
        "position":position
      }

      //console.log(payload)
      const result = await axios.post("https://irufbatlqc.execute-api.us-east-1.amazonaws.com/v1/updateline",payload,config);
      //console.log("add scene results");
      console.log(result == 1);
      await this.getData();
      await this.updateLines(this.selector.script,this.selector.act,this.selector.scene);

    }
    /*getActs: function(){
        this.acts = [];
        this.scenes = [];
				this.lines = [];
				this.selector.act = null;
				this.selector.scene = null;
        let script = this.selector.script;
        for(let x=0;x<this.userscripts.length;x++){
            record = this.userscripts[x];
            if(record['Script']==script){
                if (!this.acts.includes(record['Act'])){ this.acts.push(record['Act']);}
            }
        }
        this.acts.sort(function(a, b){return a - b});
      },
      getScenes: function(){
        this.scenes = [];
				this.lines = [];
				this.selector.scene = null;
        let script = this.selector.script;
        let act = this.selector.act;
        for(let x=0;x<this.userscripts.length;x++){
            record = this.userscripts[x];
            if (record['Act'] == act && record['Script'] == script){
                if (!this.scenes.includes(record['Scene'])){ this.scenes.push(record['Scene']);}
            }
        }
        this.scenes.sort(function(a, b){return a - b});
      },
      getLines: function(){
        console.log('getLines');
        this.lines = [];
        let script = this.selector.script;
        let act = this.selector.act;
        let scene = this.selector.scene;
        //this will get all the lines for a script, act, and scene
        //todo: coming
        for(let x=0;x<this.userscripts.length;x++){
          record = this.userscripts[x];
          if (record['Act'] == act && record['Script'] == script && record['Scene'] == scene){
            thisLine = {};
            thisLine.cue = record['Cue'];
            thisLine.line = record['Line'];
            thisLine.position = record['Position'];
            thisLine.id = record['id'];
            if (!this.lines.includes(thisLine)){ this.lines.push(thisLine);}
          }
        }
      
      this.lines.sort(function(a,b){
        return parseInt(a.position)-parseInt(b.position);
      })
				var sl = document.getElementById("sortableList");
				var sortable = Sortable.create(sl,{filter:'.newLine'});
				
					
				
            },
			saveChanges: function(){
				this.saveNeeded = false;
			},
			showSave: function(){
				this.saveNeeded = true;
				console.log("Show Save");
				
			},
			sortJson: function(jsnObj){
				var sortedArray = [];
				// Push each JSON Object entry in array by [key, value]
				for(var i in jsObj)
				{
					sortedArray.push([i, jsObj[i]]);
				}
				// Run native sort function and returns sorted array.
				return sortedArray.sort();
      }
    */
  }

}

</script>
